import React, { useEffect } from 'react'
import { SvgAppQueue, SvgDownload, SvgHeo, SvgInhouseQueue, SvgPaperQueue, SvgQrQueue } from '../../assets'
import { About, Button, Download, Footer, Header, Hero, 
  // News, 
  Partner, Service, Snap, Stories } from '../../components'

const data = [
  {
    title: "Ngantre pake aplikasi",
    desc: "Lihat cara ngantre pake aplikasi.",
    button: "/",
    img: SvgAppQueue,
    bg_color: 'bg-pink-400'
  },
  {
    title: "Scan dan klik beres",
    desc: "Cara ngantre dengan scan QR antrean.",
    button: "/",
    img: SvgQrQueue,
    bg_color: 'bg-red-300'
  },
  
]

const dataAbout = [
  {
    title: '5000+',
    desc: 'Aplikasi ngantre.com telah diunduh dan digunakan untuk mengantre dan akan terus bertambah. Memudahkan masyarakat dalam hal mengantre.',
    img: SvgDownload,
    customStyle: 'bg-blue-800'
  },
  {
    title: '4500+',
    desc: 'Layanan kami telah melayani ribuan antrean setiap hari dari puluhan tempat antrean mulai dari puskesmas, rumah sakit dan layanan publik lainnya.',
    img: SvgPaperQueue,
    customStyle: 'bg-red-500'
  },
]

export default function Home() {
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME+' | '+process.env.REACT_APP_SLOGAN;
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header home='border-b-2' />
      <Hero text={'Pusat ngantre online sejagat.'} img={SvgHeo} />
      <div className='pt-20 bg-slate-500 dark:bg-dark-1 font-primary h-fit md:h-screen'>
        <div className='h-1/4 text-center'>
          <h3 className='text-3xl text-gray-200'>3 Cara ngantre gampang<br></br> pake {process.env.REACT_APP_NAME}</h3>
          <Button text={'Yuk pelajari lebih lanjut!'} />
        </div>
        <div className='h-3/4 w-screen dark:text-slate-200 grid grid-cols-1 md:grid-cols-3 gap-10 p-20 md:mt-32 xl:mt-40 2xl:mt-52 mt-20 text-center text-xl text-gray-900 bg-white dark:bg-slate-900 rounded-t-[4rem]'>
          <div className='md:relative group'>
            <div className='cen md:group-hover:-translate-y-10 md:group-hover:scale-105 ease-out transition duration-200 md:-top-32 xl:-top-40 2xl:-top-52 md:absolute'>
              <img alt='' src={SvgInhouseQueue} className='bg-cover w-full h-40' />
              <h4 className='md:mt-2 xl:mt-5 2xl:mt-10 text-2xl font-bold'>Langsung ke tempat.</h4>
              <p className='text-sm'>Ngantre lewat mesin antrean langsung di tempat.</p>
            </div>
          </div>
          <div className='md:relative group'>
            <div className='cen md:group-hover:-translate-y-10 md:group-hover:scale-110 ease-out transition duration-200 md:-top-32 xl:-top-40 2xl:-top-52 md:absolute'>
              <img alt='' src={SvgQrQueue} className='bg-cover w-full h-40' />
              <h4 className='md:mt-2 xl:mt-5 2xl:mt-10 text-2xl font-bold'>Scan QR di tempat.</h4>
              <p className='text-sm'>Jaga jarak! Gak perlu pake mesin antrean untuk ngantre, pake HP sendiri tinggal scan. Gampang!.</p>
            </div>
          </div>
          <div className='md:relative group'>
            <div className='cen md:group-hover:-translate-y-10 md:group-hover:scale-110 ease-out transition duration-200 md:-top-32 xl:-top-40 2xl:-top-52 md:absolute'>
              <img alt='' src={SvgAppQueue} className='bg-cover w-full h-40' />
              <h4 className='md:mt-2 xl:mt-5 2xl:mt-10 text-2xl font-bold'>Pake aplikasi {process.env.REACT_APP_NAME}.</h4>
              <p className='text-sm'>Pake aplikasi lebih fleksible ngantre dari mana aja. Dapet notifikasi sebelum antrean dipanggil dan ada estimasi juga.</p>
            </div>
          </div>
        </div>
      </div>

      <About data={dataAbout} title={'Tentang Kami'} link={'https://google.com'}/>
      <Stories/>
      <Service data={data} title='Kami siap melayani' customStyle={'bg-gradient-to-b from-slate-300 to-white'}/>
      <Snap/>
      <Partner/>
      {/* <News/> */}
      <Download/>
      <Footer/>
    </div>
  )
}
