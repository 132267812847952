import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'

export default function About({ data, title, link }) {
    const classStyle = (data.length >= 4) ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10 text-center' : 'flex flex-col md:flex-row mt-10 text-center'
    return (
        <div className='h-fit p-10 md:p-20 font-primary dark:from-slate-900 dark:to-slate-900 bg-gradient-to-t from-slate-300 to-white'>
            <div className='text-center'>
                <h1 className='text-3xl font-bold dark:text-slate-200'>{title}</h1>
                <Link onClick={(e) => window.location.href = link}><Button customeStyle='px-1 md:px-5' text={'Yuk pelajari lebih lanjut!'} /></Link>
            </div>
            <div className={classStyle}>
                {data.map((item, index) => {
                    return (
                        <div key={index} className={`flex-1 p-5 rounded-[3rem] h-6/6 shadow-inner m-7 mt-20 bg-primary-main ${item.customStyle}`}>
                            <img alt={item.title} src={item.img} className='bg-cover -translate-y-24 md:-translate-y-16 lg:-translate-y-20 h-52 w-full' />
                            <h1 className='text-gray-200 font-bold text-4xl md:text-2xl lg:text-4xl mt-10'>{item.title}</h1>
                            <p className={`text-gray-300 px-0 ${(data.length >= 4) ? 'md:px-16' : 'md:px-32'}`}>{item.desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
