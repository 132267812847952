import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react'

export default function Features({data, title}) {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    console.log('a : '+carousel.current.offsetWidth);
    console.log('b : '+carousel.current.offsetWidth * currentIndex);
    console.log('c : '+maxScrollWidth.current);
    console.log('d : '+currentIndex);
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="carousel h-screen py-20 mx-auto font-primary dark:bg-dark-1">
      <h2 className="text-4xl text-center leading-8 font-semibold mb-12 text-slate-700 dark:text-slate-200 px-10">
        {title}
      </h2>
      <div className="relative overflow-hidden">
        <div className="flex justify-between absolute top left w-full h-full">
          <button
            onClick={movePrev}
            className="text-white w-20 hover:bg-gradient-to-r hover:from-slate-900 hover:to-transparent h-full text-center opacity-75 hover:opacity-50 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-2 m-0 transition-all ease-in-out duration-700"
            disabled={isDisabled('prev')}
          >
            <ArrowLeftCircleIcon className='hover:text-primary-main bg-white text-slate-900 rounded-full hover:scale-110 duration-500'/>
            <span className="sr-only">Prev</span>
          </button>
          <button
            onClick={moveNext}
            className="text-white w-20 hover:bg-gradient-to-l hover:from-slate-900 hover:to-transparent h-full text-center opacity-75 hover:opacity-50 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-2 m-0 transition-all ease-in-out duration-700"
            disabled={isDisabled('next')}
          >
            <ArrowRightCircleIcon className='hover:text-primary-main bg-white text-slate-900 rounded-full hover:scale-110 duration-500'/>
            <span className="sr-only">Next</span>
          </button>
        </div>
        <div
          ref={carousel}
          className="flex overflow-hidden scroll-smooth snap-x snap-mandatory"
        >
          {data.map((resource, index) => {
            return (
              <div key={index} className="carousel-item text-center relative w-96 h-96 snap-start pl-20 pr-14">
                <div style={{ 
                  backgroundImage: `url(${resource.img})`
                 }} className={`h-full w-full p-3 pt-10 aspect-square bg-contain bg-bottom bg-no-repeat bg-dark-primary rounded-3xl shadow-2xl ${resource.bg_color}`}>
                  <p className='text-slate-200 text-xl font-semibold'>{resource.title}</p>
                  <p className='text-slate-200 text-md'>{resource.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}
