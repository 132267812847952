import { ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { SvgGlobeFeature } from '../../assets'
import Button from '../Button'

export default function Snap() {
    const [deg, setDeg] = useState(0)
    const [index, setIndex] = useState(0)
    const handleActive = () => {
        let x = deg + 90
        setDeg(x)
        setIndex(i => {
            if(i === features.length - 1){
                return i = 0
            }else{
                return i + 1
            }
        })
    }
    const features = [
        {
            title: 'Ngantre dari mana pun bisa',
            desc: 'Bisa ngantre dari rumah atau darimana pun dan kapan pun. Lewat aplikasi atau website bisa!'
        },
        {
            title: 'Dapet notifikasi panggilan',
            desc: 'Akan ada 5 notifikasi buat kamu sebelum nomor antreanmu dipanggil. Gak perlu takut kelewat tapi harus standby ya!😁'
        },
        {
            title: 'Monitoring antrean saat ini',
            desc: 'Pantau nomor antrean saat dari aplikasi atau WhatsApp dan estimasi perkiraan nomor antreanmu dipanggil.'
        },
        {
            title: 'Banyak cara ngantre',
            desc: 'Mau langsung ke tempat, bisa pake mesin atau scan QR. Mau dari rumah bisa pake aplikasi Ngantre.com atau kunjungi website ngantre.com.'
        },
    ]
    return (
        <div className="content-center font-primary h-screen md:h-screen pt-10 dark:from-dark-1 dark:to-dark-2 dark:text-slate-200 bg-gradient-to-t from-slate-300 to-white">
            <div className='h-1/6 text-center grid content-center'>
                <h1 className='font-extrabold text-4xl'>Fitur unggulan</h1>
            </div>
            <div className="h-5/6">
                <div className='grid content-center md:h-2/5 h-4/5'>
                    <div className='grid justify-center'>
                        <div className='font-primary text-center w-screen grid justify-center'>
                            <h1 className='font-extrabold text-2xl w-screen'>{features[index].title}</h1>
                            <p className='md:px-40 lg:px-72 px-10'>{features[index].desc}</p>
                        </div>
                        <div className='text-center'>
                            <Button fromChild={() => handleActive()} text={<ChevronRightIcon className='h-7' />} />
                        </div>
                    </div>
                </div>
                <div className='w-screen md:h-3/5 h-1/5 justify-center grid overflow-hidden'>
                    <img alt='' style={{
                            transform: `rotate(${deg}deg)`,
                        }} src={SvgGlobeFeature} className='duration-1000 w-screen h-full transition' />
                </div>
            </div>
        </div>
    )
}
