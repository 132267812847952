import { ChevronDownIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import { LogoNgantre } from '../../assets'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { APP_STORE_URL, FACEBOOK_URL, INSTAGRAM_URL, PLAY_STORE_URL, YOUTUBE_URL } from '../../utils/Constant'

export default function Footer() {
    return (
        <div className='h-fit w-screen font-primary p-10 lg:px-36 dark:bg-dark-2 dark:text-slate-200 pt-20'>
            <div className='h-1/6 py-3'>
                <img alt='logo' src={LogoNgantre} className='bg-contain h-20 w-20' />
            </div>
            <div className='h-4/6'>
                <div className='grid grid-cols-1 gap-10 md:flex'>
                    <div className='w-full lg:w-4/6 grid grid-cols-2 gap-10  lg:grid-cols-4 '>
                        <div className='grid grid-cols-1 gap-2'>
                            <h1 className='text-xl font-extrabold'>Perusahaan</h1>
                            <Link to={''} className='hover:text-primary-1'>Tentang</Link>
                            <Link to={'/products'} className='hover:text-primary-1'>Produk</Link>
                            <Link to={''} className='hover:text-primary-1'>Blog</Link>
                        </div>
                        <div className='grid grid-cols-1 gap-2'>
                            <h1 className='text-xl font-extrabold'>Bergabung</h1>
                            <Link to={'/point'} className='hover:text-primary-1'>Menjadi point</Link>
                            <Link to={''} className='hover:text-primary-1'>Partner</Link>
                        </div>
                        <div className='grid grid-cols-1 gap-2'>
                            <h1 className='text-xl font-extrabold'>Penawaran</h1>
                            <Link to={''} className='hover:text-primary-1'>Paket Sistem Antrean</Link>
                            <Link to={''} className='hover:text-primary-1'>API</Link>
                        </div>
                        <div className='grid grid-cols-1 gap-2'>
                            <h1 className='text-xl font-extrabold'>Hubungi kami</h1>
                            <Link to={'/help'} className='hover:text-primary-1'>Bantuan</Link>
                            <Link to={''} className='hover:text-primary-1'>Lokasi kami</Link>
                        </div>
                    </div>
                    <div className='w-full md:w-2/6 grid grid-cols-1 gap-3'>
                        <div>
                            <h1>Ikuti kami</h1>
                            <div className='grid gap-4 md:gap-1 grid-cols-3 lg:grid-cols-4 text-center md:text-left py-3'>     
                                <Link onClick={() => window.location.href = FACEBOOK_URL} className=''><FontAwesomeIcon icon={brands('facebook')} size={'2xl'} /></Link>
                                <Link onClick={() => window.location.href = INSTAGRAM_URL} className=''><FontAwesomeIcon icon={brands('instagram')} size={'2xl'} /></Link>
                                <Link onClick={() => window.location.href = YOUTUBE_URL} className=''><FontAwesomeIcon icon={brands('youtube')} size={'2xl'} /></Link>
                            </div>
                        </div>
                        <div>
                            <h1>Download aplikasi</h1>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                                <Button fromChild={() => window.location.href = PLAY_STORE_URL} customStyle='bg-white border-2 text-primary-main border-primary-main hover:text-white dark:bg-dark-2 dark:hover:bg-primary-1' text={' Playstore'} icon={<FontAwesomeIcon icon={brands('google-play')}/>} />
                                <Button fromChild={() => window.location.href = APP_STORE_URL} customStyle='bg-white border-2 text-primary-main border-primary-main hover:text-white dark:bg-dark-2 dark:hover:bg-primary-1' text={' Appstore'} icon={<FontAwesomeIcon icon={brands('apple')}/>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='mt-10'></hr>
            <div className='1/6 grid grid-cols-1 text-center gap-4 md:text-left md:grid-cols-2 py-20'>
                <div className='grid content-center'>
                    <Link to={'/privacy-and-policy'}>Syarat dan ketentuan</Link>
                </div>
                <div className='text-center md:text-right'>
                    <Button customStyle='bg-white border-2 text-white border-slate-600 dark:border-white dark:text-white text-slate-600 hover:text-white dark:bg-dark-2 dark:hover:bg-primary-1 inline-flex' text={`Bahasa Indonesia`}  icon={<ChevronDownIcon className='w-7 h-7'/>} />
                </div>
                <div>
                    <h1>Copyright 2023 {process.env.REACT_APP_NAME} | PT. Ngantre Indonesia Persada.</h1>
                </div>
            </div>
        </div>
    )
}
