export const NGANTRE_VIA_WHATSAPP_URL = 'https://web.ngantre.com'
export const BLOG_NGANTRE_URL = 'https://blog.ngantre.com'
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.ngantre.dot&hl=en&gl=US'
export const APP_STORE_URL = 'https://apps.apple.com/app/id1661292844'
export const FACEBOOK_URL = 'https://www.facebook.com/ngantredotcom.id/'
export const INSTAGRAM_URL = 'https://www.instagram.com/ngantre.com_/?hl=en'
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCdIi9ItlfkRRpmrsyKlbhsw'
export const DATA_IMG_STORIES = [
    {
        banner: 'https://scontent.fdps2-1.fna.fbcdn.net/v/t39.30808-6/292124974_1258591681548050_89555731061460818_n.png?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=bWGcTnq82aEAX_M_DBy&tn=ANEeFB7HdzQ3EsPm&_nc_ht=scontent.fdps2-1.fna&oh=00_AfD4239gfRZqeR-F4QOUIHrqdOKz-eGLm87yxGuwokbWOA&oe=63CD9F13',
        href: 'https://www.facebook.com/ngantredotcom.id/posts/pfbid02Pa5UqQwVmNjUdRwmJ4LxyD12dmMcDznkMeDcmC2XqdDf3aBmHv1e2aGN9jn2g2Mwl'
    },
]