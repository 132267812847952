import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/Constant'
import Button from '../Button'

export default function Download() {
  return (
    <div id='download' className={`w-screen h-screen grid grid-cols-1 md:grid-cols-2 p-10 md:content-center justify-center font-primary dark:bg-dark-1 md:bg-download-destop bg-download-mobile bg-cover bg-top md:bg-center`}>
        <div className=''>
            <div>
                <h1 className='text-xl text-center md:text-left md:text-3xl lg:text-6xl dark:text-slate-200'>Mau ngantre? download aplikasi sekarang juga.</h1>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-20'>
                <Button fromChild={() => window.location.href = PLAY_STORE_URL} customStyle='text-xl border-2 bg-white border-primary-main text-primary-main hover:text-slate-100 dark:bg-slate-800' text={' Playstore'} icon={<FontAwesomeIcon icon={brands('google-play')}/>} />
                <Button fromChild={() => window.location.href = APP_STORE_URL} customStyle='text-xl border-2 bg-white border-primary-main text-primary-main hover:text-slate-100 dark:bg-slate-800' text={' Appstore'} icon={<FontAwesomeIcon icon={brands('apple')}/>} />
            </div>
        </div>
    </div>
  )
}
