import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { DATA_IMG_STORIES } from '../../utils/Constant'
import Button from '../Button'

export default function Stories() {
    const [index, setIndex] = useState(0)
    const [bar, setBar] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setBar((i) => {
                if(i === 100){
                    setIndex(ix => ix + 1)
                    return 0
                }else{
                    return i + 1
                }
            })
        }, 150);
        return () => clearInterval(interval);
    }, []);
    const handleBtn = (act) => {
        (act) ? setIndex(ix => ix + 1) : setIndex(ix => {
            return (ix === 0) ? ix - 0 : ix - 1
        })
        setBar(0)
    }
    const changeImage = (i, b) => {
        if (i < DATA_IMG_STORIES.length) {
            return (
                <div style={{ backgroundImage: `url(${DATA_IMG_STORIES[i].banner})` }} className='shadow-inner-stories h-screen relative bg-contain bg-no-repeat bg-center bg-slate-400 dark:bg-slate-900'>
                    <div className='h-screen w-screen flex md:hidden'>
                        <div onClick={() => handleBtn(false)} className='flex-1'></div>
                        <div onClick={() => handleBtn(true)} className='flex-1'></div>
                    </div>
                    <div className='flex flex-row gap-1 inset-x-10 top-10 absolute'>
                        {DATA_IMG_STORIES.map((item, ix) => {
                            if(i === ix){
                                let percentage = b+'%'
                                return (
                                    <div key={ix} className='flex-1 bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700'>
                                        <div className='bg-gray-600 h-2.5 rounded-full dark:bg-gray-300 transition-all' style={{width: percentage}}></div>
                                    </div>
                                )
                            }else{
                                if(i > ix){
                                    return (
                                        <div key={ix} className='flex-1 bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700'>
                                            <div className='bg-gray-600 h-2.5 rounded-full dark:bg-gray-300 w-[100%]'></div>
                                        </div>
                                    )
                                }
                                return (
                                    <div key={ix} className='flex-1 bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700'>
                                        <div className='bg-gray-600 h-2.5 rounded-full dark:bg-gray-300 w-[0%]'></div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className='flex flex-col md:flex-row inset-x-0 bottom-0 absolute items-center'>
                        <div className='flex-1 p-10 font-primary'>
                            <Button fromChild={() => window.location.href = DATA_IMG_STORIES[index].href} customeStyle='shadow-xl' text={'Pelajari lebih lanjut!'} />
                        </div>
                        <div className='hidden md:block md:flex-1 p-20'>
                            <div className='flex flex-row justify-end gap-4'>
                                <Button fromChild={() => handleBtn(false)} customeStyle='shadow-xl' text={<ChevronLeftIcon className='h-7' />} />
                                <Button fromChild={() => handleBtn(true)} customeStyle='shadow-xl' text={<ChevronRightIcon className='h-7' />} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            setIndex(0)
        }
    }
    return changeImage(index, bar)
}
