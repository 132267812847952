import React from 'react'

export default function Button({text, customStyle, fromChild, icon}) {
  return (
    <button onClick={(e) => fromChild(e)} className={`rounded-full transition text-white bg-primary-main hover:bg-primary-1 p-2 px-10 mt-3 ${customStyle}`}>
      {icon}
      {text}
    </button>
  )
}
