import React, { useEffect } from 'react'
import { LogoNgantre, LogoQrMenu, LogoQrTaxi, SvgProduct } from '../../assets'
import { Footer, Header, Hero, Service } from '../../components'

const data = [
  {
    title: process.env.REACT_APP_NAME,
    desc: process.env.REACT_APP_SLOGAN,
    img: LogoNgantre
  },
  {
    title: 'QR Taxi',
    desc: 'Pesan taksi, ojek dan makanan secara online.',
    img: LogoQrTaxi,
    bg_color: 'bg-black'
  },
  {
    title: 'QR Menu',
    desc: 'Pesen makan tinggal scan aja!',
    img: LogoQrMenu,
    bg_color: 'bg-[#948169]'
  },
  {
    title: 'Index Kepuasan Masyarakat',
    desc: 'Kuesioner online.',
    img: LogoNgantre,
    bg_color: 'bg-slate-300'
  },
]

export default function Product() {
  useEffect(() => {
    document.title = 'Produk kami | '+process.env.REACT_APP_SLOGAN;
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header product={'border-b-2'} />
      <Hero text='Produk kami lainnya' img={SvgProduct} />
      <Service data={data} title='Berbagai inovasi kami' />
      <Footer/>
    </div>
  )
}
