import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { SvgHelp } from '../../assets'
import { Footer, Header, Hero, Service } from '../../components'

const data = [
    {
        title: 'WhatsApp',
        desc: '+62 882-0195-32386',
        icon: <FontAwesomeIcon icon={brands('whatsapp')} size={'2xl'} className='w-full h-full' />,
        redirect: 'https://wa.me/62882019532386',
        button_text: 'Kirim WA',
        bg_color: 'bg-[#075E54]'
    },
    {
        title: 'Email',
        desc: 'support@ngantre.com',
        icon: <FontAwesomeIcon icon={solid('envelope')} size={'2xl'} className='w-full h-full' />,
        redirect: 'mailto:support@ngantre.com',
        button_text: 'Kirim Email'
    },
]

export default function Help() {
  useEffect(() => {
    document.title = 'Bantuan | '+process.env.REACT_APP_SLOGAN;
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header help={'border-b-2'} />
        <Hero text={'Butuh bantuan kami?'} img={SvgHelp} />
        <Service data={data} title={'Hubungi Kami!'}/>
        <Footer/>
    </div>
  )
}
