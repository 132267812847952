import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    PhoneIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MapPinIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { LogoNgantre } from '../../assets'
import { BLOG_NGANTRE_URL, NGANTRE_VIA_WHATSAPP_URL } from '../../utils/Constant'

const joinMenu = [
    {
        name: 'Daftar jadi Point',
        description: 'Punya pelanggan ramai? Atur antrean dengan mudah!.',
        href: '/point',
        icon: MapPinIcon,
    },
]
const callsToAction = [
    { name: 'Hubungi Sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header({ home, join, product, help }) {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]);
    return (
        <Popover className={`sticky ${scrollDirection === "down" ? "-top-24" : "top-0"} transition-all duration-500 z-30 bg-primary-main dark:bg-dark-1 shadow-lg`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6">
                <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link >
                            <span className="sr-only font-primary">{process.env.REACT_APP_NAME}</span>
                            <img
                                className="h-8 w-auto sm:h-10"
                                src={LogoNgantre}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="-my-2 -mr-2 md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-dark-2 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-main">
                            <span className="sr-only font-primary">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                        <Link to={'/'} className={`${home} font-extrabold text-base text-white hover:text-gray-900 dark:hover:text-gray-400 font-primary dark:text-gray-200`}>
                            Beranda
                        </Link>
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-white',
                                            'group inline-flex items-center rounded-md bg-primary-main dark:bg-dark-1 text-base font-medium hover:text-gray-900 dark:hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-main focus:ring-offset-2'
                                        )}
                                    >
                                        <span className={`${join} font-primary dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-400 font-extrabold`}>Gabung</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-900' : 'text-white',
                                                'ml-2 h-5 w-5 group-hover:text-white'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative grid gap-6 bg-white dark:bg-dark-2 px-5 py-6 sm:gap-8 sm:p-8">
                                                    {joinMenu.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-200 dark:hover:bg-dark-1"
                                                        >
                                                            <item.icon className="h-6 w-6 flex-shrink-0 text-primary-main" aria-hidden="true" />
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900 dark:text-gray-200 font-primary">{item.name}</p>
                                                                <p className="mt-1 text-sm text-slate-800 dark:text-gray-100 font-primary">{item.description}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                                <div className="space-y-6 bg-white dark:bg-dark-1 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                                    {callsToAction.map((item) => (
                                                        <div key={item.name} className="flow-root">
                                                            <Link
                                                                href={item.href}
                                                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100 dark:hover:bg-dark-2"
                                                            >
                                                                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                                <span className="ml-3 font-primary dark:text-gray-200">{item.name}</span>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>

                        <Link to={'/products'} className={`${product} text-base font-extrabold text-white hover:text-gray-900 dark:hover:text-gray-400 font-primary dark:text-gray-200`}>
                            Produk
                        </Link>
                        <Link onClick={() => window.location.href = BLOG_NGANTRE_URL} className={`text-base font-extrabold text-white hover:text-gray-900 dark:hover:text-gray-400 font-primary dark:text-gray-200`}>
                            Blog
                        </Link>

                        <Link to={'/help'} className={`${help} text-base font-extrabold text-white hover:text-gray-900 dark:hover:text-gray-400 font-primary dark:text-gray-200`}>
                            Bantuan
                        </Link>
                    </Popover.Group>
                    {/* btn desktop verion */}
                    <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                        <Link
                            onClick={() => window.location.href = NGANTRE_VIA_WHATSAPP_URL}
                            className="rounded-full flex w-1/2 items-center justify-center border border-transparent bg-primary-main px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-1 font-primary"
                        >
                            Ngantre sekarang!
                        </Link>
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
                    <div className="divide-y-2 divide-gray-50 rounded-lg bg-white dark:bg-dark-2 shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={LogoNgantre}
                                        alt="logo-ngantre"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-dark-1 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-main">
                                        <span className="sr-only font-primary">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    {joinMenu.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50 dark:hover:bg-dark-1"
                                        >
                                            <item.icon className="h-6 w-6 flex-shrink-0 text-primary-main font-primary" aria-hidden="true" />
                                            <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-200 font-primary">{item.name}</span>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="space-y-6 py-6 px-5">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                <Link to={'/'} className={`${home} text-base font-medium text-gray-900 dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-700 font-primary`}>
                                    Beranda
                                </Link>

                                <Link to={'/products'} className={`${product} text-base font-medium text-gray-900 dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-700 font-primary`}>
                                    Produk
                                </Link>

                                <Link onClick={() => window.location.href = BLOG_NGANTRE_URL} className={`text-base font-medium text-gray-900 dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-700 font-primary`}>
                                    Blog
                                </Link>

                                <Link to={'/help'} className={`${help} text-base font-medium text-gray-900 dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-700 font-primary`}>
                                    Bantuan
                                </Link>
                            </div>
                            <div>
                                <Link
                                    onClick={() => window.location.href = NGANTRE_VIA_WHATSAPP_URL}
                                    className="rounded-full flex w-full items-center justify-center border border-transparent bg-primary-main px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-1 font-primary"
                                >
                                    Ngantre sekarang!
                                </Link>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
