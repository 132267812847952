import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { SvgHeo, SvgJoin } from '../../assets'
import { Features, Footer, Header, Hero, Service } from '../../components'

const dataFeatures = [
    {
      title: "Sistem full online",
      desc: "Gak perlu setting kabel yang rummit, sistem sepenuhnya online jadi bisa setup mesin antrean tanpa kabel yang rumit.",
      button: "/",
      img: SvgHeo,
      bg_color: "dark:bg-green-900 bg-green-700"
    },
    {
      title: "Validasi antrean",
      desc: "Perlu validasi data peserta antrean? Bisa, cuma data peserta yang valid yang akan mendapatkan nomor antrean.",
      button: "/",
      img: SvgHeo,
      bg_color: "dark:bg-red-900 bg-red-700"
    },
    {
      title: "Pemanggilan berulang atau acak",
      desc: "Panggil nomor antrean acak dan ulang jika perlu.",
      button: "/",
      img: SvgHeo,
      bg_color: "dark:bg-purple-900 bg-purple-700"
    },
    {
      title: "Laporan antrean",
      desc: "Bisa lihat laporan antrean berdasarkan layanan, platform dll.",
      button: "/",
      img: SvgHeo,
      bg_color: "dark:bg-slate-700 bg-slate-700"
    },
    {
      title: "Memudahkan masyarakat ngantre",
      desc: "Banyak cara ngantre bisa pake mesin, scan QR, website dan aplikasi.",
      button: "/",
      img: SvgHeo,
      bg_color: "dark:bg-slate-700 bg-slate-700"
    }
]
const dataService = [
  {
    title: "Paket A",
    desc: '1. Mesin antrean (core i3)\n 2. TV 32inch\n 3. Komputer Tampilan\n 4. Speaker\n 5. Sistem antrean',
    button: "/",
    icon: <FontAwesomeIcon icon={solid('desktop')} className={'w-full h-full'} />,
    bg_color: "dark:bg-red-900 bg-red-100"
  },
  {
    title: "Paket B",
    desc: '1. Mesin antrean (core 2 duo)\n 2. TV 32inch\n 3. Komputer Tampilan\n 4. Speaker\n 5. Sistem antrean',
    button: "/",
    icon: <FontAwesomeIcon icon={solid('desktop')} className={'w-full h-full'} />,
    bg_color: "dark:bg-green-900 bg-green-100"
  },
  {
    title: "Paket C",
    desc: '1. Mesin antrean android portable\n 2. TV 32inch\n 3. Komputer Tampilan\n 4. Speaker\n 5. Sistem antrean',
    button: "/",
    icon: <FontAwesomeIcon icon={solid('mobile')} className={'w-full h-full'} />,
    bg_color: "dark:bg-blue-900 bg-blue-100"
  },
  {
    title: "Paket D",
    desc: '1. Mesin antrean tablet\n 2. TV 32inch\n 3. Komputer Tampilan\n 4. Speaker\n 5. Sistem antrean',
    button: "/",
    icon: <FontAwesomeIcon icon={solid('tablet')} className={'w-full h-full'} />,
    bg_color: "dark:bg-slate-900 bg-slate-100"
  },
  {
    title: "Paket E",
    desc: '1. Sistem antrean',
    button: "/",
    icon: <FontAwesomeIcon icon={solid('globe')} className={'w-full h-full'} />,
    bg_color: "dark:bg-orange-900 bg-orange-100"
  }
]

export default function Point() {
  useEffect(() => {
    document.title = 'Gabung jadi point | '+process.env.REACT_APP_SLOGAN;
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header join={'border-b-2'}/>
        <Hero text={`Gabung jadi point ${process.env.REACT_APP_NAME}`} img={SvgJoin} />
        <Features data={dataFeatures} title={`Keuntungan jadi point ${process.env.REACT_APP_NAME}`}/>
        <Service data={dataService} title='Paket Ngantre'/>
        <Footer/>
    </div>
  )
}
