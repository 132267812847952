import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import { TitleHeader } from '..'
import { LogoBpn, LogoPolda, LogoPusk } from '../../assets'

export default function Partner() {
    const partner = [
        {
            name: 'POLDA Bali',
            icon: LogoPolda
        },
        {
            name: 'Badan Pertanahan Nasional Kab. Badung',
            icon: LogoBpn
        },
        {
            name: 'Badan Pertanahan Nasional kota Denpasar',
            icon: LogoBpn
        },
        {
            name: 'Badan Pertanahan Nasional Kab. Tabanan',
            icon: LogoBpn
        },
        {
            name: 'Badan Pertanahan Nasional Kab. Bangli',
            icon: LogoBpn
        },
        {
            name: 'Badan Pertanahan Nasional Kab. Jembrana',
            icon: LogoBpn
        },
        {
            name: 'Badan Pertanahan Nasional Kab. Tangerang',
            icon: LogoBpn
        },
        {
            name: 'Puskesmas Penebel 1',
            icon: LogoPusk
        },
        {
            name: 'Puskesmas Abang 1',
            icon: LogoPusk
        },
        {
            name: 'Puskesmas Kuta 1',
            icon: LogoPusk
        },
        {
            name: 'Puskesmas Seririt 2',
            icon: LogoPusk
        },
        {
            name: 'Dan masih banyak lagi',
            icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/1200px-Globe_icon.svg.png'
        },
    ]
    return (
        <div className='h-fit lg:h-screen p-10 font-primary dark:bg-dark-1 bg-slate-100'>
            <div className='h-1/4 grid content-center text-center'>
                <TitleHeader text='Dimana saja bisa ngantre ?' />
            </div>
            <div className='h-fit md:h-fit lg:h-2/4 flex content-center justify-between dark:text-slate-200'>
                <div className='grid grid-cols-2 w-screen grid-rows-none md:grid-cols-4 lg:grid-cols-6 md:grid-rows-2 lg:grid-rows-2 gap-10 border-2 content-center p-10 rounded-3xl '>
                    {partner.map((item, index) => {
                        return (
                            <div key={index} className='text-center'>
                                <div className='w-full h-3/5 grid justify-center content-center'>
                                    <img alt={item.name} src={item.icon} className='w-14' />
                                </div>
                                <h1 className='h-2/5'>{item.name}</h1>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='h-1/4 flex flex-row dark:text-slate-200'>
                <div className='text-center content-center grid group flex-1'>
                    <Link to={'/point'} className='text-2xl inline-flex justify-center hover:text-primary-1'>Yuk gabung jadi point {process.env.REACT_APP_NAME} <ArrowRightIcon className='w-10 group-hover:ml-10 transition-all duration-500' /></Link>
                </div>
            </div>
        </div>
    )
}
