import React from 'react'

export default function Hero({img, text}) {
    return (
        <div className='bg-white dark:bg-dark-2 p-10'>
            <div className='grid grid-cols-1 gap-9 md:gap-4 md:grid-cols-2 content-center md:flex-row h-screen'>
                <div className='-translate-y-20 md:-translate-y-0'>
                    <h3 className='font-primary font-bold text-5xl text-center md:text-left md:text-8xl text-dark-2 dark:text-gray-200'>{text}</h3>
                </div>
                <div className=''>
                    <img alt='' src={img} className='bg-cover'/>
                </div>
            </div>
        </div>
    )
}
