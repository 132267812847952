import React from 'react'
import { Link } from 'react-router-dom'
import { TitleHeader } from '..'
import Button from '../Button'

export default function Service({ data, title, customStyle }) {
    return (
        <div className={`h-fit w-screen font-primary dark:bg-gradient-to-t dark:from-dark-2 dark:to-slate-800 bg-gradient-to-t from-slate-200 to-white ${customStyle}`}>
            <div className='h-2/6 p-10 rounded-b-[15rem] text-center content-center grid'>
                <TitleHeader text={title} />
            </div>
            <div className='4/6 p-10 pt-28 flex gap-10 dark:text-slate-200 overflow-x-scroll'>

                {data.map((item, index) => {
                    let icon = (item.icon !== undefined) ? item.icon : <img alt={item.title} src={item.img} className='bg-contain h-28 w-28' />
                    let btn = (item.button !== undefined) ? <Link to={item.button}><Button text={(item.button_text !== undefined) ? item.button_text : 'Selengkapnya'} /></Link> : (item.redirect !== undefined) ? <Link onClick={(e) => window.location.href = item.redirect}><Button text={(item.button_text !== undefined) ? item.button_text : 'Selengkapnya'} /></Link> : <></>
                    let desc = item.desc;
                    let finalDesc = desc.split('\n').map(str => <p>{str}</p>);
                    return (
                        <div key={index} className='bg-slate-300 dark:bg-dark-1 p-10 rounded-3xl w-full md:w-1/3 h-fit'>
                            <div className={`bg-primary-main w-40 h-40 rounded-3xl p-8 grid content-center shadow-2xl -translate-y-28 ${item.bg_color}`}>
                                {icon}
                            </div>
                            <div className='h-2/5'>
                                <h1 className='text-xl font-bold'>{item.title}</h1>
                                <p className='text-md'>{finalDesc}</p>
                            </div>
                            <div className='h-1/5'>
                                {btn}
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}
