import './index.css';
import './App.css';
import {Routes, Route, HashRouter} from 'react-router-dom';
import { Error, Help, Home, Point, Policy, Product } from './pages';

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/point" element={<Point />} />
          <Route path="/products" element={<Product />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy-and-policy" element={<Policy />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
